const React = require("react")
const Normalizer = require("./src/components/Normalizer").default
const { LocalToastProvider } = require("react-local-toast")

exports.wrapRootElement = ({ element }) => {
  return (
    <Normalizer>
      <LocalToastProvider>{element}</LocalToastProvider>
    </Normalizer>
  )
}
