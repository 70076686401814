import * as React from "react"
import "../style/normalize.css"
import "react-local-toast/dist/bundle.css"
// There is minified version too!
import "react-local-toast/dist/bundle.min.css"

const Normalizer = ({ children }) => {
  return <>{children}</>
}

export default Normalizer
